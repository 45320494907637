/* eslint-disable */
/**
 * Bundle of: vue-class-store
 * Generated: 2020-05-21
 * Version: 2.0.4
 */

import Vue from 'vue';

function makeOptions(model) {
  // prototype
  const prototype = Object.getPrototypeOf(model);
  if (!prototype || prototype === Object.prototype) {
    return {};
  }
  // parent options
  const extendsOptions = makeOptions(prototype);
  // descriptors
  const descriptors = Object.getOwnPropertyDescriptors(prototype);
  // options
  const name = prototype.constructor.name;
  const data = {};
  const computed = {};
  const methods = {};
  const watch = {};
  // data, string watches
  Object.keys(model).forEach((key) => {
    const value = model[key];
    if (key.startsWith('on:')) {
      watch[key.substring(3)] = value;
    } else {
      data[key] = value;
    }
  });
  // function watches, methods, computed
  Object.keys(descriptors).forEach((key) => {
    if (key !== 'constructor' && !key.startsWith('__')) {
      const ref = descriptors[key];
      const value = ref.value;
      const get = ref.get;
      const set = ref.set;
      if (key.startsWith('on:')) {
        watch[key.substring(3)] = value;
      } else if (value) {
        methods[key] = value;
      } else if (get && set) {
        computed[key] = { get: get, set: set };
      } else if (get) {
        computed[key] = get;
      }
    }
  });
  // return
  return {
    name: name,
    extends: extendsOptions,
    computed: computed,
    methods: methods,
    watch: watch,
    data: data,
  };
}
function makeVue(model) {
  const options = makeOptions(model);
  return new Vue(options);
}
function VueStore(constructor) {
  function construct() {
    const args = [];
    let len = arguments.length;
    while (len--) args[len] = arguments[len];

    const instance = new (Function.prototype.bind.apply(constructor, [null].concat(args)))();
    return makeVue(instance);
  }
  return construct;
}

function CreateVueStore(constructor) {
  function construct() {
    const args = [];
    let len = arguments.length;
    while (len--) args[len] = arguments[len];

    return new (Function.prototype.bind.apply(constructor, [null].concat(args)))();
  }

  return construct;
}

function convertToVueInstance(instance) {
  return makeVue(instance);
}

VueStore.create = makeVue;

export default VueStore;
export { makeOptions, makeVue, CreateVueStore, convertToVueInstance };
